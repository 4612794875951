import React from "react";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="54"
      viewBox="0 0 14 54"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#FFF" strokeWidth="1.5" transform="rotate(90 7 6)">
          <path d="M1.066 5.938L53.36 5.938"></path>
          <path d="M47.36 11.87688L53.12 5.93832 47.36 0"></path>
        </g>
      </g>
    </svg>
  );
}

export default Arrow;