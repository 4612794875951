import React, { useState } from 'react';
import './show.scss';
import Open from './open.svg';
import Close from './close.svg';
import Icons from './icons';

const Show = props => {
    let { name, presenter, start, end, description, socials, id } = props.data;
    const [isOpen, setOpen] = useState(false);
    const socialIcons = socials.map(social => (
            <a href={social.url} className={`${social.network}`} key={`${id}-${social.network}`} target="_blank" rel="noopener noreferrer">
                <img alt={social.network} src={Icons[social.network]} />
            </a>
        )
    );

    return (
        <div className="show">
            <div className="inner">
                <div className="details">
                    <span className="time">{start} - {end}</span>
                    <div className="copy">
                        <div className="name">
                            <h3>{name}</h3>
                            <div>with {presenter}</div>
                        </div>
                        <div className="social-icons">
                            { socialIcons }
                        </div>
                    </div>
                </div>
                { isOpen && description ? (
                    <div className={`description ${isOpen ? "open" : "closed"}`}>
                        <h4>About the show</h4>
                        { description }
                    </div>
                ) : null }
                { description ? (
                    <button onClick={() => setOpen(!isOpen)}><img src={isOpen ? Close : Open} alt="Toggle menu"/></button>
                ) : null }
            </div>
        </div>
    )
}

export default Show;