import { combineReducers } from 'redux'
import { 
    SET_MARKERS, 
    SET_COUNT, 
    SET_SCHEDULE, 
    SET_CURRENT, 
    SET_CONTENT 
} from './actionTypes'

function markers(state = [], action) {
    switch (action.type) {
        case SET_MARKERS:
            return action.markers
        default:
            return state
    }
}

function count(state = 0, action) {
    switch (action.type) {
        case SET_COUNT:
            return action.count
        default:
            return state
    }
}

function schedule(state = [], action) {
    switch (action.type) {
        case SET_SCHEDULE:
            return action.schedule
        default:
            return state
    }
}

function current(state = null, action) {
    switch (action.type) {
        case SET_CURRENT:
            return action.current
        default:
            return state
    }
}

function content(state = { strings: {}, programs: {}, archive: [] }, action) {
    switch (action.type) {
        case SET_CONTENT:
            const { strings, programs, archive } = action.content;
            return {
                strings,
                programs,
                archive
            }
        default:
            return state
    }
}

const radioApp = combineReducers({
    markers,
    count,
    schedule,
    current,
    content
})

export default radioApp