import { 
    SET_MARKERS, 
    SET_COUNT, 
    SET_SCHEDULE, 
    SET_CURRENT,
    SET_CONTENT 
} from './actionTypes'

export function setMarkers(markers) {
    return {
        type: SET_MARKERS,
        markers
    }
}

export function setCount(count) {
    return {
        type: SET_COUNT,
        count
    }
}

export function setSchedule(schedule) {
    return {
        type: SET_SCHEDULE,
        schedule
    }
}

export function setCurrent(current) {
    return {
        type: SET_CURRENT,
        current
    }
}

export function setContent(content) {
    return {
        type: SET_CONTENT,
        content
    }
}