import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import _ from "underscore";
import { MeshBasicMaterial, Mesh, SphereGeometry } from "three";
import * as TWEEN from "es6-tween";
import ReactGlobe from "react-globe";
import Blur from "../transitions/blur";
import offices from "../common/offices";
import Settings from "../common/settings";
import extractCollaboratorCity from "./extract-collaborator";
import Config from "./config";
import "./globe.scss";

import milkyway from "./textures/milkyway-4k-gray-cream-res.jpg";
import clouds from "./textures/clouds.png";
import globe from "./textures/globe-4k-gray-cream-res.jpg";
import milkyway1k from "./textures/milkyway-4k-gray-cream-res-1k.jpg";
import clouds1k from "./textures/clouds-1k.png";
import globe1k from "./textures/globe-4k-gray-cream-res-1k.jpg";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

const selectCurrentCity = createSelector(
  (state) => {
    const current = state.current;
    const schedule = state.schedule;
    const { programs } = state.content;

    return {
      current,
      schedule,
      programs,
    };
  },
  ({ current, schedule, programs }) =>
    extractCollaboratorCity(current, programs, schedule) || "Melbourne"
);

const Globe = (props) => {
  const { isShown, isObscured } = props;
  const markers = useSelector(
    (state) => (state ? state.markers : []),
    _.isEqual
  );
  const currentCity = useSelector(selectCurrentCity);
  const { cameraOptions, focusOptions, lightOptions, globeOptions } = Config;

  const updatedOffices = offices.map((office) => {
    let newOffice = Object.assign({}, office);
    newOffice.color =
      newOffice.city === currentCity
        ? Settings.activeOfficeColor
        : newOffice.color;
    newOffice.coordinates[0] += Math.random() * 0.001;
    newOffice.coordinates[1] += Math.random() * 0.001;
    return newOffice;
  });

  const m = [...updatedOffices, ...markers];

  const markerRenderer = (marker) => {
    const { color, type } = marker;
    const material = new MeshBasicMaterial({
      color,
    });
    material.transparent = true;
    material.opacity = 0.5;

    if (type === "office") {
      const officeGeometry = new SphereGeometry(5, 32, 32);
      let mesh = new Mesh(officeGeometry, material);
      return mesh;
    } else {
      const listenerGeometry = new SphereGeometry(2, 10, 10);
      let mesh = new Mesh(listenerGeometry, material);
      return mesh;
    }
  };

  return (
    <div className={`globe-wrap ${isObscured ? "obscured" : "shown"}`}>
      <Blur className="globe" config={{ friction: 150 }} shown={isShown}>
        <ReactGlobe
          globeTexture={window.innerWidth < 1023 ? globe1k : globe}
          globeBackgroundTexture={
            window.innerWidth < 1023 ? milkyway1k : milkyway
          }
          globeCloudsTexture={window.innerWidth < 1023 ? clouds1k : clouds}
          markers={m}
          options={{
            ambientLightColor: "#f6f2ee",
            ambientLightIntensity: 0.5,
            pointLightIntensity: 0.5,
            pointLightPositionRadiusScales: [-1, 1.5, -2.5],
            cameraFOV: 50,
            globeCloudsOpacity: 0.1,
            globeGlowCoefficient: 0.2,
            globeGlowColor: "#fff7e7",
            globeGlowPower: 6,
            globeGlowRadiusScale: 0.2,
            enableCameraZoom: false,
            markerRenderer,
            markerTooltipRenderer: (marker) =>
              marker.city
                ? marker.city
                : `${marker.coordinates[0]}, ${marker.coordinates[1]}`,
          }}
        />
      </Blur>
    </div>
  );
};

export default Globe;
