import React, { useState } from "react";
import Content from "../common/content";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import Artefact from "./artefact";
import Moment from "moment-timezone";
import FilterBtn from '../program/filter.svg';

import "simplebar/dist/simplebar.min.css";
import "./archive.scss";

const Archive = (props) => {
  const { strings, archive = [] } = useSelector((state) =>
    state ? state.content : {}
  );
  const getContent = Content(strings || {});
  const [filter, setFilter] = useState("all");
  const [showSidenav, setSidenav] = useState(false)

  let sortedArchive = archive.sort(
    (a, b) => Moment(a.date).valueOf() - Moment(b.date).valueOf()
  );
  let jumpLinks = null;
  for (let artefact of sortedArchive) {
    jumpLinks = jumpLinks === null ? {} : jumpLinks;
    const dateMoment = Moment.utc(artefact.date);
    const dateKey = dateMoment.format("MMMM.YYYY");
    jumpLinks[dateKey] = dateMoment.format("MMMM YYYY");
  }

  return (
    <div className="max-width columns about archive">
      <div className="left">
        <h1>{getContent("archive.title")}</h1>
        <button className="sidenav-toggle" onClick={() => setSidenav(!showSidenav)}>
          <img src={FilterBtn} alt="open menu"/>
        </button>

        {jumpLinks !== null ? (
          <div className={`jump-links ${showSidenav ? 'active' : ''}`}>
            <span className="jump">Jump to</span>
            <div className="jump-links-wrap">
              <button
                className={filter === "all" ? "active" : ""}
                key="jump-link-all"
                onClick={() => setFilter("all")}
              >
                All
              </button>
              {Object.keys(jumpLinks).map((link) => (
                <button
                  className={filter === link ? "active" : ""}
                  key={`jump-link-${link}`}
                  onClick={() => setFilter(link)}
                >
                  {jumpLinks[link]}
                </button>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="center interactive">
        <SimpleBar style={{ maxHeight: "100%" }}>
          <div className="archive-listing">
            {sortedArchive.map((artefact, i) => {
              const dateKey = Moment.utc(artefact.date).format("MMMM.YYYY");
              if (filter === "all" || filter === dateKey) {
                return (
                  <Artefact key={`${artefact.trackID}-${i}`} data={artefact} />
                );
              }

              return null;
            })}
          </div>
        </SimpleBar>
      </div>
      <div className="right" />
    </div>
  );
};

export default Archive;
