import React, { useEffect, useState } from "react";
import {
  Router,
  Location,
  createHistory,
  LocationProvider,
} from "@reach/router";
import ReactGA from "react-ga";
import Settings from "./components/common/settings";
import useInterval from "@use-it/interval";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  setMarkers,
  setCount,
  setSchedule,
  setCurrent,
  setContent,
} from "./redux/actions";

import Footer from "./components/footer";
import Header from "./components/header";
import Intro from "./components/intro";
import Globe from "./components/globe";
import Home from "./components/home";
import About from "./components/about";
import Program from "./components/program";
import Profiles from "./components/profiles";
import Help from "./components/help";
import Fade from "./components/transitions/fade";
import MobileMenu from "./components/mobile-menu";
import { CookiesProvider } from "react-cookie";
import Archive from "./components/archive";

import Content from "./data/content.json";
import Schedule from "./data/schedule.json";
import Listeners from "./data/listeners.json";

ReactGA.initialize("UA-162536875-1");
const history = createHistory(window);

const AppRouter = () => {
  const dispatch = useDispatch();
  const [showIntro, toggleIntro] = useState(true);
  const introComplete = () => toggleIntro(false);

  const getListeners = () => {
    // axios.get(`${Settings.api}/listeners`).then((response) => {
    // let hours = response.data;
    let hours = Listeners;
    let newMarkers = [];
    let uniqueListeners = {};

    hours.forEach((hour) => {
      Object.keys(hour.listeners).forEach((listener_id) => {
        let listener = hour.listeners[listener_id];
        uniqueListeners[listener_id] = 1;

        newMarkers.push({
          type: "listener",
          id: listener.client_id,
          city: listener.location.division,
          coordinates: [
            listener.location.latitude,
            listener.location.longitude,
          ],
          value: 1,
          color: "#F96B00",
        });
      });
    });

    dispatch(setMarkers([...newMarkers]));
    dispatch(setCount(Object.keys(uniqueListeners).length));
    // });
  };

  const getSchedule = () => {
    // axios.get(`${Settings.api}/schedule`).then((response) => {
    // let schedule = response.data;
    let schedule = Schedule;
    dispatch(setSchedule(schedule));
    // });
  };

  const getCurrent = () => {
    axios
      .get("https://public.radio.co/stations/sb0ee13329/status")
      .then((response) => {
        let currentData = response.data;
        let current = {
          name:
            currentData.collaborators && currentData.collaborators.length > 0
              ? currentData.collaborators[0].name
              : "",
          title: currentData.current_track.title,
          id:
            currentData.collaborators && currentData.collaborators.length > 0
              ? currentData.collaborators[0].id
              : "",
        };
        dispatch(setCurrent(current));
      });
  };

  const getContent = () => {
    // axios.get(`${Settings.api}/content`).then((response) => {
    // let content = response.data;
    let content = Content;
    dispatch(setContent(content));
    // });
  };

  useEffect(() => {
    getListeners();
    getSchedule();
    getCurrent();
    getContent();
  });

  useInterval(getListeners, 1000 * 60);
  useInterval(getSchedule, 1000 * 60);
  useInterval(getCurrent, 1000 * 30);

  history.listen((window) => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <LocationProvider history={history}>
      <Location>
        {({ location }) => (
          <div className="container">
            <Globe isShown={true} isObscured={location.pathname !== "/"} />
            <Fade className="wrap" config={{ friction: 50 }} shown={!showIntro}>
              <Header location={location} />
              <Fade className="content" config={{ friction: 10 }} shown={true}>
                <Router>
                  <Home path="/" />
                  <About path="/about" />
                  <MobileMenu path="/menu" />
                  <Program path="/schedule" />
                  <Archive path="/archive" />
                  <Profiles path="/profiles" />
                  <Help path="/help" />
                </Router>
              </Fade>
              <Footer location={location} />
            </Fade>
            <Fade
              className="modal"
              config={{ friction: 50 }}
              shown={showIntro}
              startOn={true}
            >
              <Intro onComplete={introComplete} key="intro" />
            </Fade>
          </div>
        )}
      </Location>
    </LocationProvider>
  );
};

function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </CookiesProvider>
  );
}

export default App;
