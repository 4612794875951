import React from "react";

export default ({ trackURL, name }) => (
    <iframe
        title={name}
        width="100%"
        height="166"
        scrolling="no"
        frameBorder="no"
        src={`https://w.soundcloud.com/player/?url=${trackURL}&amp;color=000000&amp;auto_play=false&amp;show_artwork=false`}
    ></iframe>
)
