import React from 'react'
import { useSelector } from 'react-redux';
import Legend from '../legend';

import './home.scss';

const Home = () => {
    const current = useSelector(state => state ? state.current : null)

    return (
        <div className="max-width columns home">
            <div className="left">
                { current ? (
                    <div className="now-playing">
                        <h4>Now playing</h4>
                        <h2>{current.title}</h2>
                        { current.name !== '' ? <div className="with">with {current.name}</div> : null }
                    </div>
                ) : null }
            </div>
            <div className="center"/>
            <div className="right">
                <Legend />
            </div>
        </div>
    )
}

export default Home;