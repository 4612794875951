import React from 'react';
import AKQA from '../common/akqa.js';
import Content from '../common/content';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';
import './about.scss';

const About = props => {
    const content = useSelector(state => state ? state.content : {});
    const getContent = Content(content.strings || {});

    return (
        <div className="max-width columns about">
            <div className="left">
                <h1>{getContent('about.title')}</h1>
            </div>
            <div className="center interactive">
                <SimpleBar style={{ maxHeight: "100%" }}>
                    <div className="hero">
                        {getContent('about.body')}
                    </div>
                    <div className="company">
                        <div className="logo">
                            <p>{getContent('about.logoLabel')}</p>
                            <AKQA />
                        </div>
                        <div className="note" dangerouslySetInnerHTML={{ __html: getContent('about.footnote') }} >
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <div className="right" />
        </div>
    )
}

export default About;