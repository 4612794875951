import React, { useEffect } from 'react';
import './intro.scss';
import Arrow from './arrow.js';
import AKQA from '../common/akqa.js';
import Lottie from 'react-lottie';
import LogoJSON from './wfhfm-logo.json';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import Content from '../common/content';

const oneDay = 1000 * 60 * 60 * 24;
const cookieExpiry = oneDay;

const Intro = props => {
    const { onComplete } = props;
    const [cookies, setCookie] = useCookies(['wfhfm-acknowledgement']);
    const cookieExpired = cookies['wfhfm-acknowledgement'] && new Date() - parseInt(cookies['wfhfm-acknowledgement']) > cookieExpiry;
    const showAcknowledgement = !cookies['wfhfm-acknowledgement'] || cookieExpired;
    const content = useSelector(state => state ? state.content : {});
    const getContent = Content(content.strings || {});

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: LogoJSON
    };

    useEffect(() => {
        window.addEventListener('wheel', onComplete);
        window.addEventListener('touchon', onComplete);
        const didShowLogoAcknowledgement = showAcknowledgement;
        setTimeout(onComplete, didShowLogoAcknowledgement ? 8000 : 4000);

        return (() => {
            window.removeEventListener('touchon', onComplete);
            window.removeEventListener('wheel', onComplete);

            if (didShowLogoAcknowledgement) {
                setCookie('wfhfm-acknowledgement', new Date().getTime());
            }
        })
    }, [onComplete, setCookie, showAcknowledgement]);

    return (
        <div className="intro">
            <AKQA />
            { showAcknowledgement ? (
                <p>{getContent('intro.welcome')}</p>
            ) : (
                <Lottie 
                    options={defaultOptions}
                    width={200}
                    height="auto"
                />
            ) }
            <Arrow />
        </div>
    )
}

export default Intro;