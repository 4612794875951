const ExtractCollaboratorCity = (collaborator, programs, schedule) => {
    if (!collaborator || !schedule || !schedule.events || !programs) return null;

    // find programs in schedule with this collaborator id
    let events = schedule.events.filter(event => event.collaborator_id === collaborator.id);

    // find program data that matches the event
    // return the city if we find it
    for (let event of events) {
        if (programs[event.id]) {
            return programs[event.id].city;
        }
        else if (programs[event.parent_id]) {
            return programs[event.parent_id].city;
        }
    };

    return null;
}

export default ExtractCollaboratorCity;