import React from 'react';

import './header.scss';
import Logo from './logo.svg';
import Burger from './burger.svg';
import Close from './menu-close.svg';
import { Link } from "@reach/router"

const Header = props => {
    const { location } = props;

    return (
        <header>
            <div className="max-width columns">
                <div className="left">
                    <Link to="/"><img src={Logo} alt="WFH-FM" /></Link>
                </div>
                <div className="center" />
                <div className="right">
                    <nav>
                        <Link to="/about">About</Link>
                        <Link to="/schedule">Schedule</Link>
                        <Link to="/archive">Archive</Link>
                    </nav>
                    <nav className="mobile-nav">
                        { location.pathname === "/menu" ? (
                            <a href="/" onClick={(e) => { 
                                e.preventDefault();
                                window.history.back() }
                                }>
                                <img src={Close} alt="Close menu"/>
                            </a>
                        ) : (
                            <Link to="/menu">
                                <img src={Burger} alt="Open menu"/>
                            </Link>
                        ) }
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header;