import React from 'react';
import { Link } from "@reach/router"
import Legend from '../legend';

import './mobile-menu.scss';

const MobileMenu = props => {
    return (
        <div className="max-width columns mobile-menu">
            <div className="center interactive">
                <nav>
                    <Link to="/about">About</Link>
                    <Link to="/schedule">Schedule</Link>
                    <Link to="/archive">Archive</Link>
                </nav>
                <Legend />
            </div>
        </div>
    )
}

export default MobileMenu;