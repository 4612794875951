import React from 'react';
import './profiles.scss';

const Profiles = props => {
    return (
        <div className="max-width columns">
            <div className="left">
                <h1>Profiles</h1>
            </div>
            <div className="center"/>
            <div className="right" />
        </div>
    )
}

export default Profiles;