import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Show from './components/show';
import Moment from 'moment-timezone';
import SimpleBar from 'simplebar-react';
import Select from 'react-select';
import FilterBtn from './filter.svg';
import { Tween, Easing } from 'es6-tween';

import 'simplebar/dist/simplebar.min.css';
import './program.scss';

const Header = () => {
    const schedule = useSelector(state => state ? state.schedule : [])
    const { programs } = useSelector(state => state ? state.content : { programs: {} })

    const collaborators = {}
    Object.keys(schedule.collaborators).forEach(c => collaborators[schedule.collaborators[c].id] = schedule.collaborators[c]);
    
    const playlists = {}
    Object.keys(schedule.playlists).forEach(p => playlists[schedule.playlists[p].id] = schedule.playlists[p]);

    const [timezone, setTimezone] = useState(Moment.tz.guess());
    const [day, setDay] = useState(Moment().format("dddd").toLowerCase());
    const [timeOfDay, setTimeOfDay] = useState('morning');
    const [showSidenav, setSidenav] = useState(false);

    const mondayMidnight = Moment().tz(timezone).startOf('isoWeek');
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const programElem = useRef();
    const morningElem = useRef(null);
    const afternoonElem = useRef(null);
    const eveningElem = useRef(null);
    const weekNav = useRef(null);

    const buildShowData = (ev) => {
        const { id, start, end, collaborator_id } = ev;
        const startTime = Moment.utc(start).tz(timezone);
        const endTime = Moment.utc(end).tz(timezone);
        const pid = ev.parent_id || ev.id;
        const collaborator = collaborator_id ? collaborators[collaborator_id] : null;
        let pName = collaborator ? collaborator.name : (playlists[ev.playlist_id] ? playlists[ev.playlist_id].name : "Anonymous");
        const presenter = programs[pid] && programs[pid].presenter ? programs[pid].presenter : pName;
        const name = programs[pid] ? programs[pid].name : presenter;
        const socials = [];

        if (programs[pid]) {
            for (let s in programs[pid].socials) {
                if (programs[pid].socials[s] !== "") {
                    socials.push({
                        network: s,
                        url: programs[pid].socials[s]
                    })
                }
            }
        }

        return {
            id,
            name,
            presenter,
            start: startTime.format("hh:mm A"),
            end: endTime.format("hh:mm A"),
            description: programs[pid] ? programs[pid].description : null,
            socials
        };
    }

    let morningShows = [];
    let afternoonShows = [];
    let eveningShows = [];

    for (let d = 0; d < 7; d++) {
        if (days[d].toLowerCase() === day) {
            const today = mondayMidnight.add(d, 'day');
            const lunch = today.clone().add(12, 'hour');
            const dinner = today.clone().add(18, 'hour');
            const midnight = today.clone().add(1, 'day').subtract(1, 'second');

            schedule.events.forEach(event => {
                let startTime = Moment.utc(event.start).tz(timezone);

                if (startTime.isBetween(today, midnight)) {
                    const show = <Show data={buildShowData(event)} key={event.id}/>;
                    if (startTime.isBefore(lunch)) {
                        morningShows.push(show);
                    }
                    else if (startTime.isBefore(dinner)) {
                        afternoonShows.push(show);
                    }
                    else if (startTime.isBefore(midnight)) {
                        eveningShows.push(show);
                    }
                }
                
            });
            break;
        }
    }
    
    let timesOfDay = [];
    if (morningShows.length) timesOfDay.push("morning");
    if (afternoonShows.length) timesOfDay.push("afternoon");
    if (eveningShows.length) timesOfDay.push("evening");

    const handleProgramNav = (e) => {
        e.preventDefault();
        const timeOfDay = e.target.hash.substr(1);
        setTimeOfDay(timeOfDay);
        let top = 0;

        switch(timeOfDay) {
            case "morning":
                top = morningElem.current.offsetTop;
                break;
            case "afternoon":
                top = afternoonElem.current.offsetTop;
                break;
            case "evening":
                top = eveningElem.current.offsetTop;
                break;
            default:
                top = 0;
                break;
        }

        // Tween the program to the selected time of day
        const elem = programElem.current.getScrollElement();
        new Tween({ top: elem.scrollTop })
            .to({ top: top }, 500)
            .easing( Easing.Quadratic.Out )
            .on('update', ({ top }) => {
                elem.scrollTop = top
            })
            .start();
    }

    const handleDay = (e) => {
        e.preventDefault();
        const selectedDay = e.target.hash.substr(1);
        setDay(selectedDay);

        if (timesOfDay.length) {
            setTimeOfDay(timesOfDay[0]);
        }

        // Tween the program to the top
        const elem = programElem.current.getScrollElement();
        new Tween({ top: elem.scrollTop })
            .to({ top: 0 }, 500)
            .easing( Easing.Quadratic.Out )
            .on('update', ({ top }) => {
                elem.scrollTop = top
            })
            .start();
        
        // Tween the week-nav to the selected day
        const weekNavElem = weekNav.current;
        new Tween({ left: weekNavElem.scrollLeft })
            .to({ left: e.target.offsetLeft }, 500)
            .easing( Easing.Quadratic.Out )
            .on('update', ({ left }) => {
                weekNavElem.scrollLeft = left
            })
            .start();
    }

    return (
        <div className="max-width columns program">
            <div className="left">
                <h1>Schedule</h1>
                <span className="jump">Jump to</span>
                <div className={`${showSidenav ? "active" : ""} program-sidenav`}>
                    <nav className="program-nav">
                        { timesOfDay.map((tod, i) => (
                            <a key={`${tod}-${i}`} onClick={handleProgramNav} className={`${timeOfDay === tod ? "active" : ""}`}  href={`#${tod}`}>{tod}</a>    
                        )) }
                    </nav>
                    <div className="tz-select">
                        <Select
                            value={{value: timezone, label: timezone}}
                            onChange={selectedTz => setTimezone(selectedTz.value)}
                            options={Moment.tz.names().map(tz => ({ value: tz, label: tz }))}
                            className='react-select-container'
                            classNamePrefix='react-select'
                        />
                    </div>
                </div>
                <button className="sidenav-toggle" onClick={() => setSidenav(!showSidenav)}><img src={FilterBtn} alt="open menu"/></button>
            </div>
            <div className="center interactive">
                <div className="center-inner">
                    <div className="program-grid">
                        <div className="program-grid-week">
                            <nav className="week-nav" ref={weekNav}>
                                { days.map((d, i) => {
                                        return (
                                            <a 
                                                onClick={handleDay} 
                                                className={`${day === d.toLowerCase() ? "active" : ""}`} 
                                                href={`#${d.toLowerCase()}`}
                                                key={`${d}-${i}`}>
                                                    {d}
                                            </a>
                                        )
                                    })
                                }
                            </nav>
                            <SimpleBar style={{ maxHeight: "100%" }} ref={programElem}>
                                <div className={`program-grid-day ${day}`}>
                                    <div className="program-grid-day-inner">
                                        { morningShows.length > 0 ? (
                                            <div className="time-of-day" id="morning" ref={morningElem}>
                                                <h2>Morning</h2>
                                                { morningShows }
                                            </div>
                                        ) : null }

                                        { afternoonShows.length > 0 ? (
                                            <div className="time-of-day" id="afternoon" ref={afternoonElem}>
                                                <h2>Afternoon</h2>
                                                { afternoonShows }
                                            </div>
                                        ) : null }

                                        { eveningShows.length > 0 ? (
                                            <div className="time-of-day" id="evening" ref={eveningElem}>
                                                <h2>Evening</h2>
                                                { eveningShows }
                                            </div>
                                        ) : null }
                                    </div>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right" />
        </div>
    )
}

export default Header;