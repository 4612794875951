import React from 'react';
import './help.scss';

const Help = props => {
    return (
        <div className="max-width columns help">
            <div className="left">
                <h1>Trouble Connecting?</h1>
            </div>
            <div className="center interactive">
                <p>WFH-FM is also available via network stream. Copy and paste the link below into a media player like iTunes, VLC or Winamp.</p>
                <a href="http://stream.radio.co/sb0ee13329/listen.m3u" target="_blank" rel="noopener noreferrer">http://stream.radio.co/sb0ee13329/listen.m3u</a>
            </div>
            <div className="right" />
        </div>
    )
}

export default Help;