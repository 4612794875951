import React, { useEffect, useRef } from 'react';
import './player.scss';

const $ = window.jQuery || null;

const Player = () => {
    const radioElem = useRef();

    useEffect(() => {
        if ($) {
            $('.radioplayer').radiocoPlayer();
        }
    }, []);

    return (
        <div className="radio-wrap">
            <div 
                ref={radioElem}
                className="radioplayer" 
                data-src="https://stream.radio.co/sb0ee13329/listen" 
                data-playbutton="true"
                data-volumeslider="true"
                data-elapsedtime="false"
                data-nowplaying="false"
                data-showplayer="false"
                data-albumartwork="true" />
        </div>
    )
}

export default Player;