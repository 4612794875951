import milkyway from './textures/milkyway-4k-gray-cream-res.jpg';
import clouds from './textures/clouds.png';
import globe from './textures/globe-4k-gray-cream-res.jpg';
import milkyway1k from './textures/milkyway-4k-gray-cream-res-1k.jpg';
import clouds1k from './textures/clouds-1k.png';
import globe1k from './textures/globe-4k-gray-cream-res-1k.jpg';


const config = {
    data: {
      keyword: 'AKQA WFH-FM',
    },
    cameraOptions: {
      enableZoom: false,
      cameraFOV: 66,
    },
    focusOptions: {
      enableDefocus: false,
    },
    globeOptions: {
      cloudsSpeed: 10,
      cloudsOpacity: 0.1,
      glowCoefficient: 0.2,
      glowColor: '#fff7e7',
      glowPower: 6,
      glowRadiusScale: 0.2,
      texture: window.innerWidth < 1023 ? globe1k : globe,
      backgroundTexture: window.innerWidth < 1023 ? milkyway1k : milkyway,
      cloudsTexture: window.innerWidth < 1023 ? clouds1k : clouds
    },
    lightOptions: {
      ambientLightColor: '#f6f2ee',
      ambientLightIntensity: 0.5,
      pointLightIntensity: 0.5,
      pointLightPositionRadiusScales: [-1, 1.5, -2.5],
    },
};

export default config;