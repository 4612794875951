import React from 'react';
import './legend.scss';

const Legend = props => {
    return (
        <div className="legend">
            <h4>Map Legend</h4>
            <ul className="legend-list">
                <li className="marker studios">Studios</li>
                <li className="marker broadcaster">Broadcasting Now</li>
                <li className="marker listener">Listeners</li>
            </ul>
        </div>
    )
}

export default Legend;