import youtube from "./icons/youtube.svg";
import website from "./icons/website.svg";
import twitter from "./icons/twitter.svg";
import tiktok from "./icons/tiktok.svg";
import soundcloud from "./icons/soundcloud.svg";
import snapchat from "./icons/snapchat.svg";
import linkedin from "./icons/linkedin.svg";
import instagram from "./icons/instagram.svg";
import facebook from "./icons/facebook.svg";

const Icons = {
    youtube,
    website,
    twitter,
    tiktok,
    soundcloud,
    snapchat,
    linkedin,
    instagram,
    facebook
};

export default Icons;