const officeData = {
    "Aarhus": [56.152272, 10.188241],
    "Amsterdam": [52.366030, 4.890173],
    "Atlanta": [33.781666, -84.387111],
    "Auckland": [-36.850719, 174.765547],
    "Berlin": [52.523469, 13.399039],
    "Cairo": [30.071380, 31.221632],
    "Copenhagen": [55.678770, 12.563773],
    "Dubai": [25.097829, 55.156292],
    "San Salvador": [13.706495, -89.240812],
    "Gothenburg": [57.710693, 11.943742],
    "Gurgaon": [28.451982, 77.068071],
    "London": [51.520725, -0.101933],
    "Los Angeles": [33.999481, -118.472512],
    "Melbourne": [-37.832234, 144.971054],
    "Milan": [45.440718, 9.163501],
    "New York": [40.737915, -73.992689],
    "Paris": [48.856764, 2.332088],
    "Portland": [45.523480, -122.682701],
    "Patato": [51.522350, -0.136233],
    "San Francisco": [37.782650, -122.398006],
    "Sao Paulo": [-23.556793, -46.698578],
    "Shanghai": [31.206848, 121.477903],
    "Stockholm": [59.332271, 18.066681],
    "Sydney": [-33.857881, 151.203242],
    "Tokyo": [35.645976, 139.722522],
    "Venice": [45.565839, 12.424712],
    "Washington DC": [38.903143, -77.064625]
}

let offices = Object.keys(officeData).map(officeName => {
    let office = officeData[officeName];

    return {
        type: 'office',
        id: officeName,
        city: officeName,
        coordinates: office,
        value: 1,
        color: '#FF9FD6'
    };
});

export default offices;