import React, { useState } from "react";
import Player from "./player";
import Open from '../player/vol.svg';
import Close from '../program/components/show/close.svg';
import Moment from 'moment-timezone';

const Artefact = ({ data }) => {
    const { trackURL, name, presenter, date } = data
    const [isOpen, setOpen] = useState(false);
    const archiveTime = Moment.utc(date).format("Do MMMM, YYYY")

    return (
        <div className="archive-show">
            <div className="inner">
                <div className="details">
                    <span className="time">{archiveTime}</span>
                    <div className="copy">
                        <div className="name">
                            <h3>{name}</h3>
                            <div>with {presenter}</div>
                        </div>
                    </div>
                </div>
                { isOpen ? (
                    <div className="player-wrap">
                        <Player trackURL={trackURL} name={name} />
                    </div>
                ) : null }
                <button onClick={() => setOpen(!isOpen)}><img src={isOpen ? Close : Open} alt="Toggle menu"/></button>
            </div>
        </div>
    )
}

export default Artefact
