import React from 'react';
import { animated, useTransition } from 'react-spring';

const Fade = ({ children, className, config, shown, startOn = false }) => {
  const transitions = useTransition(shown, null, {
    from: { opacity: startOn ? 1 : 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config,
  });

  return transitions.map(
    ({ item, key, props }) => {
      return item ? (
        <animated.div key={key} className={className} style={props}>
          {children}
        </animated.div>
      ) : null;
    },
  );
}

export default Fade;