import React from 'react';
import Player from '../player';
import AKQA from '../common/akqa.js';
import { Link } from "@reach/router"
import './footer.scss';

const Footer = ({ location }) => {

    return (
        <footer className={location.pathname === "/" ? "home" : ""}>
            <div className="max-width columns">
                <div className="left">
                    <Player />
                </div>
                <div className="center"/>
                <div className="right">
                    <div><Link to="/help">Trouble connecting?</Link></div>
                    <div>All rights reserved</div>
                    <AKQA />
                </div>
            </div>
        </footer>
    )
}

export default Footer;